<div id="wrapper-header">
   

    <header>
        <div class="top-section">
            <div class="container">
                <ul class="list-inline">
                    <li>
                        <a href="tel:+91 9851 192 192"> <i class="fa fa-phone" aria-hidden="true"></i> &nbsp; +91 9851 192 192</a>
                    </li>
                    <li>
                        <a href="mailto:support@bhangarwale.in"> <i class="fa fa-paper-plane" aria-hidden="true"></i>&nbsp; support@bhangarwale.in</a>
                    </li>
                </ul>

            </div>
        </div>

    </header>
    <div class="navbar-wrapper">
        <div class="container">

            <nav class="navbar navbar-inverse navbar-static-top">
                <div class="container">
                    <div class="navbar-header">
                        <button type="button" id="toggle-button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <a class="navbar-brand" routerLink="home"><img src="assets/images/logo.jpg" class="img-responsive"></a>
                    </div>
                    <div id="navbar" class="navbar-collapse collapse">
                        <ul class="nav navbar-nav navbar-right">
                            <li class="active"><a routerLink="/">Home</a></li>
                            <li><a routerLink="price-list">Price List</a></li>
                            <li><a routerLink="/request"  *ngIf="(isLoggedIn() === 'true' && getUserType() === 'user') || isLoggedIn() === 'false'">Request Pickup</a></li>
                           <!--  <li><a routerLink="shop">Shop</a></li> -->
                            <li><a routerLink="services">Services</a></li>
                            <li><a routerLink="about">About Us</a></li> 
                            <li><a routerLink="login" *ngIf="isLoggedIn() === 'false'">Sign In</a></li>
                            <li><a routerLink="signup" *ngIf="isLoggedIn() === 'false'">Sign Up</a></li>
                            <li class="dropdown" *ngIf="isLoggedIn() === 'true'">
                                <button class="dropbtn"> 
                                    <i class="fa fa-user-circle-o" aria-hidden="true"></i>&nbsp;
                                    <i class="fa fa-caret-down"></i>
                                </button>
                                <div class="dropdown-content">
                                    <a routerLink="dashboard" *ngIf="isLoggedIn() === 'true'">Dashboard</a>
                                    <a routerLink="profile" *ngIf="isLoggedIn() === 'true'">Profile</a>
                                    <a routerLink="change-password" *ngIf="isLoggedIn() === 'true'">Change Password</a>
                                    <a *ngIf="isLoggedIn() === 'true'" routerLink="/" (click)="logout()">Logout</a>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>

        </div>
    </div>
<div id="banner-wrap">
<div id="stage" class="stage">
<div id="bg" class="stage"></div>
<div id="clouds" class="stage"></div>
<div id="skyline3" class="stage"></div>
<div id="helicopter" class="stage"></div>
<div id="skyline" class="stage"></div>

<div id="cars" class="stage"></div>

<div id="buildings" class="stage"></div>
<div id="man"></div>
</div>
</div>
</div>