import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators,FormBuilder } from '@angular/forms';
import { ApiService } from '../../api.service';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  submitted = false;
  error;

  passwordForm = this.fb.group({
	  email: ['', [Validators.required,Validators.email]],
	  password: ['', [Validators.required,Validators.minLength(6)]],
	  cpassword: ['', [Validators.required]],
	  code : [this.route.snapshot.paramMap.get("code")]
	});

  constructor(private fb: FormBuilder,private apiService: ApiService,private route: ActivatedRoute) { }

  ngOnInit() {
  }
    get f() { return this.passwordForm.controls; }

  onSubmit() {
   
    this.error  = ''; 
   document.getElementById("thanks").style.display = "none";

   this.submitted = true;
	// TODO: Use EventEmitter with form value
	//console.warn(this.passwordForm.value);

	 if (this.passwordForm.invalid) {
            return;
        }


         if(this.passwordForm.value.password !== this.passwordForm.value.cpassword)
    {
       this.error = 'Password and confirm password should be same';
       return; 
    }


	this.apiService.resetPassword(this.passwordForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        this.submitted = false;
        //$('#contactForm').hide();
        this.passwordForm.reset();
        document.getElementById("thanks").style.display = "block"; 
      }
      if(data.status == false)
      {
        this.submitted = true;
        //$('#contactForm').hide();
          this.error  = data.error; 
        document.getElementById("thanks").style.display = "none"; 
      }
      
    });
	}

}
