
 <!--Our Supporters  -->

<section id="why">
<div class="container">
<h1>Our Supporters</h1>
<div id="owl-demo2" class="support owl-carousel owl-theme">
<div class="item"><a class="thumbnail"><img alt="" src="assets/images/client1.jpg"></a></div>
<div class="item"><a class="thumbnail"><img alt="" src="assets/images/client2.jpg"></a></div>
<div class="item"><a class="thumbnail"><img alt="" src="assets/images/client3.jpg"></a></div>
<div class="item"><a class="thumbnail"><img alt="" src="assets/images/client4.jpg"></a></div>
<div class="item"><a class="thumbnail"><img alt="" src="assets/images/client1.jpg"></a></div>
<div class="item"><a class="thumbnail"><img alt="" src="assets/images/client2.jpg"></a></div>
<div class="item"><a class="thumbnail"><img alt="" src="assets/images/client3.jpg"></a></div>
<div class="item"><a class="thumbnail"><img alt="" src="assets/images/client4.jpg"></a></div>
</div>
</div>
</section>

<!--Our Supporters  -->
<div class="clearfix"></div>

     <section id="socail-media">
        <div class="container">
            <ul>
<li><a href="https://www.facebook.com/Bhangarwale-382622632394297/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
<!-- <li><a href="#" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
<li><a href="#" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li> -->
<li><a href="https://www.instagram.com/bhangarwale19/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
</ul>
        </div>
    </section>

    <footer>
        <!-- <img src="assets/images/fot-img.png" class="img-responsive"> -->
        <div class="container fot-overlap">
            <div class="row">

              
                 <div class="col-md-4 wow fadeInLeft animated" data-wow-delay="0.3s">

                    <h4>Bhangarwale</h4>
                    <p>We find tons of recyclable things every day. After properly recycling, you help items meet their next best use, spending tons on tons of raw material, time, energy, and expenditure.
We generate as much as 1200 grams of paper and as much as 1.6KG of plastic waste every month per single household, 


</p>
                    <p><a routerLink="about" class="btn-footer"> More Info </a> &nbsp;
                        <a routerLink="contact" class="btn-footer"> Contact Us</a></p>

                </div>
              
           
             <div class="col-md-4 wow fadeInLeft animated" data-wow-delay="0.6s">

                    <h4> Useful Links</h4>
                    <ul>
                        <li><i class="fa fa-hand-o-right" aria-hidden="true"></i> <a class="hvr-forward" routerLink="price-list"> Price List</a> </li>
                        <li><i class="fa fa-hand-o-right" aria-hidden="true"></i> <a class="hvr-forward" routerLink="request"> Request Pickup</a> </li>
                        <li><i class="fa fa-hand-o-right" aria-hidden="true"></i> <a class="hvr-forward" routerLink="terms-condition"> Terms & Conditions</a> </li>
                        <li><i class="fa fa-hand-o-right" aria-hidden="true"></i> <a class="hvr-forward" routerLink="privacy-policy"> Privacy Policy</a> </li>

                    </ul>

                </div>

              <div class="col-md-4 wow fadeInLeft animated" data-wow-delay="0.9s">
                    <h4> Address</h4>

                    <ul class="qulick-link">
                        <li><span class="icon-pin"></span>Dwarka, Nashik, Maharashtra 422001</li>
                        <li><span class="icon-telephone"></span> <a href="tel:+91 9851 192 192"> +91 9851 192 192</a> </li>
                        <li><span class="icon-sent-mail"></span> <a href="mailto:support@bhangarwale.in"> support@bhangarwale.in</a> </li>

                    </ul>

                </div>

          
            </div>
            <div class="row">
                <div class="copyright text-center">
                    <div class="col-md-12 col-sm-12">
                        Copyright ©{{year}} All rights reserved | Design & Developed By <a href="https://magnusideas.com/" target="_blank"><strong>Magnus Ideas</strong></a>
                    </div>
                   
                </div>
            </div>
           
        </div>
    </footer>