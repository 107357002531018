<section id="terms">
  <h1 class="wow fadeInLeft" data-wow-delay="0.3s"> Terms and Conditions</h1>
  <div class="container condition">
    <h3> By using our Services, you are agreeing to these terms. Please read them carefully. </h3>
    <h3 class="wow fadeInLeft" data-wow-delay="0.3s"> <strong>Our service </strong></h3>
    <p class="wow fadeInRight" data-wow-delay="0.3s"> - You must follow this terms in order to use our services. </p>
    <p class="wow fadeInRight" data-wow-delay="0.3s"> - For our pickup service, you must have to provide your accurate information like address.You pickup will held on the schedule you provide while submitting the request. You can reschedule or cancel your pickup request anytime after submitting your request. You must have 15Kg or more scrap for pickup, otherwise pickup charges may apply. Rates of all material are fixed as mentioned on our price list. For the rates of other materials that are not listed on the list your can contact us. </p>

    <h3 class="wow fadeInLeft" data-wow-delay="0.6s"> <strong> Your Account </strong> </h3>
    <p class="wow fadeInRight" data-wow-delay="0.6s"> - In order to use our service, you need an account. </p>
    <p class="wow fadeInRight" data-wow-delay="0.6s"> - You need a valid mobile number to create your account. Your mobile number must need to be verified. In order to place a pickup request, you must have to provide your accurate address of the pickup, if the address was not accurate enough then your pickup request may get canceled. </p>
    <p class="wow fadeInRight" data-wow-delay="0.6s"> - We want your information just for delivering our services. In some case, you may also require providing your other personal information such as "Date of Birth" etc. All the information you provide about yourself must be correct, if we find the information inaccurate then your account may be suspended. </p>

    <h3 class="wow fadeInLeft" data-wow-delay="0.9s"> <strong> Privacy policy </strong> </h3>
    <p class="wow fadeInRight" data-wow-delay="0.9s"> - The bhangarwale's privacy policy explains what information we collect and how we use it. </p>

    <h3 class="wow fadeInLeft" data-wow-delay="1.2s"> <strong> Changes to our terms </strong> </h3>
    <p class="wow fadeInRight" data-wow-delay="1.2s"> - We have the discretion to update our terms at any time, If we decide to change our terms, we will post those changes on this page, and/or update the Terms modification date below.</p>

    <h3 class="wow fadeInLeft" data-wow-delay="1.5s"> <strong> Contact us </strong> </h3>
    <p class="wow fadeInRight" data-wow-delay="1.5s"> - If you have any questions about our Terms and Conditions, the practices of this site, or your dealings with this site, please contact us at:</p>
    <p class="wow fadeInRight" data-wow-delay="1.8s"> - Dwarka, Nashik, Maharashtra 422001</p>
     <p class="wow fadeInRight" data-wow-delay="2.0s"><a href="tel:+91 9851 192 192"> - +91 9851 192 192 </a></p>

     <p class="wow fadeInRight" data-wow-delay="2.1s"><a href="mailto:support@bhangarwale.in">- support@bhangarwale.in </a></p>


 
</div>
</section>
<!-- terms-condition section ends -->


