    <section id="inner-page">
        <div class="container">
            <h1>Create your account</h1>
            <div class="login-container">
                <div id="output"></div>
                <div class="avatar text-center"><img src="assets/images/logo.jpg" class="img-responsive img-center"></div>
                <div class="form-box">
                   <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" id="registerForm">
                        <input type="text" placeholder="Mobile Number" formControlName="mobile" required="">
                         <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                            <div *ngIf="f.mobile.errors.required">Mobile number is required</div>
                                               <div *ngIf="f.mobile.errors.pattern">Mobile number should be 10 digit number</div>
                                        </div>

                        <input type="email" placeholder="email" formControlName="email" required="">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                            <div *ngIf="f.email.errors.email">Invalid email</div>
                                        </div>

                        <input type="text" placeholder="Name" formControlName="name" required="">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">Name is required</div>
                                              <div *ngIf="f.name.errors.pattern">Only Alphabets Allowed</div>
                                        </div>

                        <input type="password" placeholder="Password" formControlName="password" required="" minlength="6">
                         <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                            <div *ngIf="f.password.errors.minlength">Password length should be greater than 6</div>
                                        </div>

                        <input type="password" placeholder="Confirm Password" formControlName="cpassword" required="">
                        <div *ngIf="submitted && f.cpassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.cpassword.errors.required">Confirm Password is required</div>
                                        </div>

                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <select class="form-control" formControlName="cityid" required="" (change)="cityChanged($event)">
                                    <option value="">Select City</option>
                                    <option *ngFor="let c of cities"  value="{{c.Id}}">{{c.City}}</option>
                                </select>
                                <div *ngIf="submitted && f.cityid.errors" class="invalid-feedback">
                                            <div *ngIf="f.cityid.errors.required">City is required</div>
                                        </div>

                            </div>
                            <div class="col-md-6 col-sm-6">
                                <select class="form-control" formControlName="localityid" required="" (change)="localityChanged($event)">
                                    <option value="">Select Locality</option>
                                    <option *ngFor="let l of localities"  value="{{l.Id}}">{{l.Locality}}</option>
                                </select>
                                  <div *ngIf="submitted && f.localityid.errors" class="invalid-feedback">
                                            <div *ngIf="f.localityid.errors.required">Locality is required</div>
                                        </div>
                            </div>
                        </div>
                        <input type="text" placeholder="Enter nearest Landmark" formControlName="landmark" required="">
                        <div *ngIf="submitted && f.landmark.errors" class="invalid-feedback">
                                            <div *ngIf="f.landmark.errors.required">Landmark is required</div>
                                        </div>

                        <textarea rows="5" placeholder="Eg:- House/Flat No, Apartment Name" formControlName="address" required=""></textarea>
                          <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                            <div *ngIf="f.address.errors.required">Address is required</div>
                                        </div>
                        <p id="error"  class="form-error">{{error}}</p>

                        <p class="small-text">By clicking on "Sign Up" you agree to our <a routerLink="/terms-condition">Terms and Conditions.</a></p>

                        <!-- <button class="next-btn hvr-sweep-to-right login" type="submit">SIGN UP</button> -->
                         <input type="submit" value="SIGN UP" name="" type="submit" class="next-btn hvr-sweep-to-right login">
                        <div class="panel-footer">
                            <span class="text-muted"><span class="p-r-xs">Already had an account?</span> <a routerLink="/login" class="btn btn-default btn-sm">Sign in</a></span>
                        </div>
                    </form>
                    <p class="text-center" id="thanks" hidden="">
                                Registered Successfully!
                            </p>
                </div>
            </div>
        </div>
    </section>

    
    <div class="clearfix"></div>