<section id="about">
  <div class="container">
    <div class="row">
    <div class="col-sm-5">
      <img src="assets/images/about.jpg" class="img-responsive">
    </div>
  <div class="col-sm-7 about-conten wow fadeInLeft" data-wow-delay="0.3s">
            
            <h1>About Us</h1>
            <p>The Bhangarwale is a gateway service that focuses on helping people sell and get paid for their household junks like newspaper, steel, plastic books, copies, metal, etc. This aims to recycle, reuse, reduce the up-cycle and resell the scrap</p>
            <p>
             Getting involved in responsible waste management is a platform for residents and is best in class technology scrap recycling logistics. 
            </p>

            <h4><b>Our Vision : </b></h4>
            <p>Cleaning every home, cleaning the country.</p>
            <p>Bhangarwale... reuse the rejected scrap with an encouraging thought of serving our country by standardizing waste management. Bhangarwale is an online platform that helps bridge the gap between waste recyclers and users.Using Bhangarwale, any user can reuse or donate any unused products or objects in a simple or comfortable way. Helping customers in home clearing, therefore. No more waste collection, transportation of scrap from home to a scrap dealer, no more dumping sites, and unhygienic recycling.</p>
            <p>And a bonus for that! Reward yourself and help protect the environment. E-waste, newspaper, papers, cardboard, iron, wood, shoes, furniture, glass bottles, plastic bottles, cosmetics, etc.</p>

            <h4><b>Our Mission : </b></h4>
            <p>Recycle Anywhere, Anytime! We will assess our success not by how many clients we serve, but by how we treat our clients-we will aim to meet or exceed the client's standards.</p>
          <!--   <p>
              <div class="swachh-img"><img src="assets/assets/images/swachh-bharat.png" class="img-responsive"></div>
            </p> -->
    
      </div>
    </div>
    </div>
</section>
<div class="clearfix"></div>
<section id="news-feed">
  <div class="container">
    <div class="sectiontitle">
    <h1>Our Imapact</h1>
</div>
<div id="projectFacts" class="sectionClass">
    <div class="fullWidth eight columns">
        <div class="row projectFactsWrap ">
            <div class="col-md-3 col-sm-3 col-xs-6"><div class="item wow fadeInUpBig animated animated" data-number="12" style="visibility: visible;">
                <!-- <i class="fa fa-briefcase"></i> -->
                <p id="number1" class="number">350 KG.</p>
                <span></span>
                <p>Paper Recycled</p>
            </div>
          </div>
           
            <div class="col-md-3 col-sm-3 col-xs-6"><div class="item wow fadeInUpBig animated animated" data-number="400" style="visibility: visible;">
                <!-- <i class="fa fa-coffee"></i> -->
                <p id="number3" class="number">400 KG.</p>
                <span></span>
                <p>Plastic Recycled</p>
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-xs-6">
            <div class="item wow fadeInUpBig animated animated" data-number="246" style="visibility: visible;">
               <!--  <img src="assets/images/e-waste-icon.png"> -->
                <p id="number4" class="number">500 KG.</p>
                <span></span>
                <p>E - Waste Recycled</p>
            </div>
          </div>
             <div class="col-md-3 col-sm-3 col-xs-6"><div class="item wow fadeInUpBig animated animated" data-number="55" style="visibility: visible;">
               <!--  <i class="fa fa-smile-o"></i> -->
                <p id="number2" class="number">80</p>
                <span></span>
                <p>Happy clients</p>
            </div>
          </div>
        </div>
    </div>
</div>
  </div>
</section>
      <!-- FOOTER -->
