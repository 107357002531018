import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators,FormBuilder } from '@angular/forms';
import { ApiService } from '../../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {
 categories;
 cities;
 mindate;
 otp;
  localities;
  submitted1 = false;
  error1;
  submitted2 = false;
  error2;
  submitted3 = false;
  error3;
  submitted4 = false;
  error4;
  mobilenoForm = this.fb.group({
	  mobile: ['', [Validators.required,Validators.pattern("^[0-9]{10}$")]],
    type: ['pickup']
	});

  otpForm = this.fb.group({
    otp: ['', [Validators.required,Validators.pattern("^[0-9]{6}$")]]
  });


  addressForm = this.fb.group({
	    name: ['', [Validators.required,Validators.pattern("^[a-zA-Z ]*$")]],
	  email: ['', [Validators.email]],
	  city: ['', [Validators.required]],
	  locality: ['', [Validators.required]],
	  landmark: ['', [Validators.required]],
	  address: ['', [Validators.required]],
	  requestid: [''],
	  userid: [''],
	  newuser: [''],
     contact: [''],
    type: ['pickup'],
      cityid: ['', [Validators.required]],
    localityid: ['', [Validators.required]],
	});

	requestForm = this.fb.group({
	  date: ['', [Validators.required]],
	  time: ['', [Validators.required]],
	  requestid: [''],
	  items: [''],
    type: ['pickup']
	});

  constructor(private fb: FormBuilder,private apiService: ApiService,private router: Router) { }

  ngOnInit() {

    var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  var day = currentDate.getDate();
  var month = currentDate.getMonth() + 1;
  var month1 = month.toString();
  if(month < 10)
  { 
    month1 = '0'+ month1;
  }
  var year = currentDate.getFullYear();
  this.mindate = year +'-'+ month1 +'-'+ day;

   this.apiService.getItemsByCategory().subscribe((data)=>{
       this.categories = data;
    });



    if(localStorage.getItem('isLoggedIn') == 'true')
    {
      this.mobilenoForm.setValue({mobile: localStorage.getItem('mobile'),type:'pickup'});
    }

      this.apiService.getCities().subscribe((data)=>{
      //console.log(data);
       this.cities = data;
    });
  }

  cityChanged(event: Event)
  {

   let selectedOptions = event.target['options'];
   let selectedIndex = selectedOptions.selectedIndex;
   let selectElementText = selectedOptions[selectedIndex].text;
   this.addressForm.controls.city.setValue(selectElementText);
    this.apiService.getLocalitiesByCity(this.addressForm.value.cityid).subscribe((data)=>{
      //console.log(data);
       this.localities = data;
    });

  }

  localityChanged(event: Event)
  {

   let selectedOptions = event.target['options'];
   let selectedIndex = selectedOptions.selectedIndex;
   let selectElementText = selectedOptions[selectedIndex].text;
   this.addressForm.controls.locality.setValue(selectElementText);
  }

  get f1() { return this.mobilenoForm.controls; }
  get f2() { return this.addressForm.controls; }
  get f3() { return this.requestForm.controls; }
  get f4() { return this.otpForm.controls; }

  onSubmit1() {
   
   this.error1  = ''; 
   document.getElementById("thanks").style.display = "none";

   this.submitted1 = true;



	 if (this.mobilenoForm.invalid) {
            return;
        }


        document.getElementById("step1").style.display = "none"; 
        document.getElementById("step4").style.display = "block"; 
        
  
	this.apiService.validateMobileno(this.mobilenoForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        this.submitted1 = false;
        this.mobilenoForm.reset();
        this.otp = data.otp.toString();
       
        

        

       // this.addressForm.value.requestid = data.requestid;

        if(data.newuser == 'yes')
        {
           this.addressForm.setValue({name: '',contact:'',city:'',locality:'',email:'',cityid:'',localityid:'',landmark:'',address: '',requestid:data.requestid,userid:0,newuser:data.newuser,type:'pickup'});

           this.addressForm.controls.cityid.setValue(1);
           this.addressForm.controls.city.setValue('Nashik');

            this.apiService.getLocalitiesByCity(1).subscribe((data)=>{
            //console.log(data);
             this.localities = data;
          });
        }
        else
        { 
          this.apiService.getLocalitiesByCity(data.user.CityId).subscribe((data)=>{
            //console.log(data);
             this.localities = data;
          });
          this.addressForm.setValue({name: data.user.Name,contact: data.user.Mobile,email: data.user.Email,city:data.user.City,locality:data.user.Locality,cityid:data.user.CityId,localityid:data.user.LocalityId,landmark:data.user.Landmark,address: data.user.Address,requestid:data.requestid,userid:data.user.Id,newuser:data.newuser,type:'pickup'});
        }

        console.log(this.otp);
      }
      if(data.status == false)
      {
        this.submitted1 = true;
        this.error1  = data.error; 
      }
      
    });
	}

  checkOtp()
  {

   this.error4  = ''; 
   this.submitted4 = true;

   //console.log(this.otpForm.value);
   //return;



   if (this.otpForm.invalid) {
            return;
        }

        if(this.otpForm.value.otp !== this.otp)
        {
           this.error4  = 'Invalid OTP'; 
        }
        else
        {

              this.otpForm.reset();
               this.submitted4 = false;
              document.getElementById("step4").style.display = "none";
          var element = document.getElementById("step1");
        element.classList.remove("active");
        var element = document.getElementById("tab1");
        element.classList.remove("active");
        element.classList.add("disabled");

        document.getElementById("step2").style.display = "block"; 
        var element = document.getElementById("step2");
        element.classList.add("active");
        var element = document.getElementById("tab2");
        element.classList.add("active");
        element.classList.remove("disabled");

        }

        


  }

	onSubmit2() {
   
   this.error2  = ''; 
   //document.getElementById("thanks").style.display = "none";

   this.submitted2 = true;

	 if (this.addressForm.invalid) {
            return;
        }

  
	this.apiService.saveAddress(this.addressForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        this.submitted2 = false;
        this.addressForm.reset();
       
        document.getElementById("step2").style.display = "none"; 
        var element = document.getElementById("step2");
        element.classList.remove("active");
        var element = document.getElementById("tab2");
        element.classList.remove("active");
        element.classList.add("disabled");

        document.getElementById("step3").style.display = "block"; 
        var element = document.getElementById("step3");
        element.classList.add("active");
        var element = document.getElementById("tab3");
        element.classList.add("active");
        element.classList.remove("disabled");

        this.requestForm.setValue({requestid:data.requestid,date:'',time:'',items:'',type:'pickup'});

      }
      if(data.status == false)
      {
        this.submitted2 = true;
        this.error2  = data.error; 
      }
      
    });
	}

   isLoggedIn()
  {
    return localStorage.getItem('isLoggedIn');
  }


  onSubmit3() {
   
   this.error3  = ''; 
   //document.getElementById("thanks").style.display = "none";

   this.submitted3 = true;

   var time = (<HTMLInputElement>document.getElementById("timepicker")).value;

   this.requestForm.controls.time.setValue(time);

   var checkboxes = document.getElementsByName("item");
   var checkboxesChecked = [];

   for (var i=0; i<checkboxes.length; i++) {
      var inputValue = (<HTMLInputElement>document.getElementById(checkboxes[i].id)).value;
      if ((<HTMLInputElement>document.getElementById(checkboxes[i].id)).checked) {
        checkboxesChecked.push(inputValue);
     }
   }

   if(checkboxesChecked.length == 0)
   { 
     this.error3  = 'Please select atleast one item';
     return; 
   }

   this.requestForm.value.items = checkboxesChecked;

	 if (this.requestForm.invalid) {
            return;
        }

  
	this.apiService.requestPickup(this.requestForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        this.submitted3 = false;
        this.requestForm.reset();
       
        document.getElementById("step3").style.display = "none"; 
        var element = document.getElementById("step3");
        element.classList.remove("active");
        var element = document.getElementById("tab3");
        element.classList.remove("active");
        element.classList.add("disabled");

         document.getElementById("step1").style.display = "block"; 
        var element = document.getElementById("step1");
        element.classList.add("active");
        var element = document.getElementById("tab1");
        element.classList.add("active");
        element.classList.remove("disabled");

         if(localStorage.getItem('isLoggedIn') == 'true')
    {
      this.mobilenoForm.setValue({mobile: localStorage.getItem('mobile'),type:'pickup'});
    }

          this.router.navigate(['/thank-you']);

      }
      if(data.status == false)
      {
        this.submitted3 = true;
        this.error3  = data.error; 
      }
      
    });
	}




}
