 <section id="donation-list">

        <div class="container">
            <div class="text-center">
                <h1>Thanks for the request.We will contact you soon.</h1>
            </div>
        </div>
    </section>

    <!-- donation section ends -->

    
    <div class="clearfix"></div>