import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PriceListComponent } from './pages/price-list/price-list.component';
import { RequestComponent } from './pages/request/request.component';
import { ShopComponent } from './pages/shop/shop.component';
import { ServicesComponent } from './pages/services/services.component';
import { AboutComponent } from './pages/about/about.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { TermsConditionComponent } from './pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';

import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { PickupDetailsComponent } from './pages/pickup-details/pickup-details.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { RequestServiceComponent } from './pages/request-service/request-service.component';
import { ServiceDetailsComponent } from './pages/service-details/service-details.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { LoaderService } from './loader.service';
import { LoaderInterceptor } from './loader.interceptor';
import { LoaderComponent } from './pages/loader/loader.component';


@NgModule({
  declarations: [
    AppComponent,
    PriceListComponent,
    RequestComponent,
    ShopComponent,
    ServicesComponent,
    AboutComponent,
    LoginComponent,
    SignupComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    DashboardComponent,
    ProfileComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PickupDetailsComponent,
    ThankYouComponent,
    RequestServiceComponent,
    ServiceDetailsComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [LoaderService,{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
