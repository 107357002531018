    <section id="inner-page">
        <div class="container">
            <h1>Forgot Password</h1>
            <div class="login-container">
                <div id="output"></div>
                <div class="form-box">
                   <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" id="passwordForm">
                        

                        <input type="email" placeholder="email" formControlName="email" required="">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                            <div *ngIf="f.email.errors.email">Invalid email</div>
                                        </div>

                                        <p  class="form-error">{{error}}</p>

                        <!-- <button class="next-btn hvr-sweep-to-right login" type="submit">SIGN UP</button> -->
                         <input type="submit" value="Send" name="" type="submit" class="next-btn hvr-sweep-to-right login">
                    </form>
                    <p class="text-center" id="thanks" hidden="">
                                Password reset link sent to your email address
                            </p>
                </div>
            </div>
        </div>
    </section>

    
    <div class="clearfix"></div>