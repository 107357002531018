  <section id="terms">
        <h1 class="wow fadeInLeft" data-wow-delay="0.3s"> Privacy Policy  </h1>
        <div class="container condition">
            <h3 class="wow fadeInLeft" data-wow-delay="0.3s"> <strong> Personal Identification Information </strong></h3>
            <p class="wow fadeInRight" data-wow-delay="0.3s"> - We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, place an order, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. </p>

            <p class="wow fadeInRight" data-wow-delay="0.3s"> - Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities. </p>

            <h3 class="wow fadeInLeft" data-wow-delay="0.6s"> <strong> Non-Personal Identification Information </strong> </h3>
            <p class="wow fadeInRight" data-wow-delay="0.6s"> - We may collect non-personal identification information about Users whenever they interact with our Site. The non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Sites, such as the operating system and the Internet service providers utilized and other similar information. </p>

            <h3 class="wow fadeInLeft" data-wow-delay="0.9s"> <strong> Web Browser Cookies </strong> </h3>
            <p class="wow fadeInRight" data-wow-delay="0.9s"> - Our Site uses "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. The user may choose to set their web browser to refuse cookies or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly. </p>

            <h3 class="wow fadeInLeft" data-wow-delay="1.2s"> <strong> How We Use Collected Information </strong> </h3>
            <p class="wow fadeInRight" data-wow-delay="1.2s"> - We may collect and use Users personal information for the following purposes: </p>
            <h2 class="wow fadeInLeft" data-wow-delay="1.2s"> <strong> - To improve customer service  </strong> </h2>
            <p class="wow fadeInRight" data-wow-delay="1.5s"> - The information you provide helps us respond to your customer service requests and support needs more efficiently. </p>
            <h2 class="wow fadeInLeft" data-wow-delay="1.2s"> <strong> - To personalize user experience   </strong> </h2>
            <p class="wow fadeInRight" data-wow-delay="1.5s"> We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.</p>

            <h2 class="wow fadeInLeft" data-wow-delay="1.2s"> <strong> - To improve our Site </strong> </h2>
            <p class="wow fadeInRight" data-wow-delay="1.5s"> We may use feedback you provide to improve our products and services. </p>

            <h2 class="wow fadeInLeft" data-wow-delay="1.2s"> <strong> - To share your information with third parties </strong> </h2>
            <p class="wow fadeInRight" data-wow-delay="1.5s"> We may share or sell information with third parties for marketing or other purposes. </p>

            <h2 class="wow fadeInLeft" data-wow-delay="1.2s"> <strong> - To send periodic emails </strong> </h2>
            <p class="wow fadeInRight" data-wow-delay="1.5s"> We may share or sell information with third parties for marketing or other purposes. </p>

            <h3 class="wow fadeInLeft" data-wow-delay="1.5s"> <strong> How We Protect Your Information </strong> </h3>
            <p class="wow fadeInRight" data-wow-delay="1.5s"> - We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site. </p>

            <p class="wow fadeInRight" data-wow-delay="1.8s"> - Sensitive and private data exchange between the Site and its Users happens over an SSL secured communication channel and is encrypted and protected with digital signatures. Our Site is also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users.</p>

            <h3 class="wow fadeInLeft" data-wow-delay="1.5s"> <strong> Sharing Your Personal Information </strong> </h3>
            <p class="wow fadeInRight" data-wow-delay="1.5s"> - We may use third-party service providers to help us operate our business and the Site or administer activities on our behalfs, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us your permission. </p>

            <h3 class="wow fadeInLeft" data-wow-delay="1.5s"> <strong> Third Party Websites </strong> </h3>
            <p class="wow fadeInRight" data-wow-delay="1.5s"> - Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. </p>

            <p class="wow fadeInRight" data-wow-delay="1.5s"> - In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website's own terms and policies. </p>

            <h3 class="wow fadeInLeft" data-wow-delay="1.5s"> <strong> Changes To This Privacy Policy </strong> </h3>
            <p class="wow fadeInRight" data-wow-delay="1.5s"> - We have the discretion to update this privacy policy at any time. When we do, we will post a notification on the main page of our Site, revise the updated date at the bottom of this page and send you an email. </p>

            <p class="wow fadeInRight" data-wow-delay="1.5s"> - We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications. </p>

            <h3 class="wow fadeInLeft" data-wow-delay="1.5s"> <strong> Your Acceptance Of These Terms </strong> </h3>
            <p class="wow fadeInRight" data-wow-delay="1.5s"> - By using this Site, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
            </p>

            <h3 class="wow fadeInLeft" data-wow-delay="1.5s"> <strong> Contact Us </strong> </h3>
            <p class="wow fadeInRight" data-wow-delay="1.5s"> - If you have any questions about our Terms and Conditions, the practices of this site, or your dealings with this site, please contact us at:</p>
            <p class="wow fadeInRight" data-wow-delay="1.8s">  - Dwarka , Nashik, Maharashtra 422001</p>
            <p class="wow fadeInRight" data-wow-delay="2.0s"><a href="tel:+91 9851 192 192"> - +91 9851 192 192 </a></p>

            <p class="wow fadeInRight" data-wow-delay="2.1s"><a href="mailto:support@bhangarwale.in"> - support@bhangarwale.in </a></p>

        </div>
    </section>
    <!-- terms-condition section ends -->

