import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators,FormBuilder } from '@angular/forms';
import { ApiService } from '../../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  submitted = false;
  error;
  loginForm = this.fb.group({
	  mobile: ['', [Validators.required,Validators.pattern("^[0-9]{10}$")]],
	  password: ['', [Validators.required]]
	});

  constructor(private fb: FormBuilder,private apiService: ApiService,private router: Router) { }

  ngOnInit() {
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
   
     this.error  = ''; 
   document.getElementById("thanks").style.display = "none";

   this.submitted = true;
	// TODO: Use EventEmitter with form value
	//console.warn(this.loginForm.value);

	 if (this.loginForm.invalid) {
            return;
        }

  
	this.apiService.login(this.loginForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        this.submitted = false;
        this.loginForm.reset();
        //document.getElementById("thanks").style.display = "block"; 
        localStorage.setItem('isLoggedIn', "true");
        localStorage.setItem('id', data.user.Id);
        localStorage.setItem('name', data.user.Name);
        localStorage.setItem('email', data.user.Email);
        localStorage.setItem('mobile', data.user.Mobile);
        localStorage.setItem('usertype', data.user.UserType);
        localStorage.setItem('localityid', data.user.LocalityId);
        this.router.navigate(['/dashboard']);
      }
      if(data.status == false)
      {
        this.submitted = true;
        this.error  = data.error; 
        document.getElementById("thanks").style.display = "none"; 
      }
      
    });
	}


}
