import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.css']
})
export class PriceListComponent implements OnInit {

  items;

  constructor(private apiService: ApiService) { }

  ngOnInit() {

  	 this.apiService.getItems().subscribe((data)=>{
      //console.log(data);
       this.items = data;
    });
  }

}
