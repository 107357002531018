      <section id="inner-page" *ngIf="isLoggedIn() === 'true' && getUserType() === 'vendor'">
        <div class="container">
            <h1>Pickup requests in your area</h1>

            <div class="row">

            	 <table class="table table-hover">
					    <thead>
					      <tr>
					        <th>Pickup Date</th>
					        <th>Pickup Time</th>
					        <th>Details</th>
					      </tr>
					    </thead>
				    <tbody>
				      <tr  *ngFor="let p of openpickups"> 
				        <td>{{p.Date | date : "dd MMM y" }}</td>
				        <td>{{p.Time}}</td>
				        <td>{{p.names }}</td>
				        <td><a (click)="acceptRequest('pickup',p.Id)">Accept</a></td>
				      </tr>
				    </tbody>
				  </table>

            </div>

        </div>
    </section>

       <section id="inner-page" *ngIf="isLoggedIn() === 'true' && getUserType() === 'vendor'" style="display: none;">
        <div class="container">
            <h1>Service requests in your area</h1>

            <div class="row">

            	 <table class="table table-hover">
					    <thead>
					      <tr>
					        <th>Service Date</th>
					        <th>Time</th>
					        <th>Details</th>
					      </tr>
					    </thead>
				    <tbody>
				      <tr  *ngFor="let p of openservices"> 
				        <td>{{p.Date | date : "dd MMM y" }}</td>
				        <td>{{p.Time}}</td>
				        <td>{{p.names }}</td>
				        <td><a (click)="acceptRequest('service',p.Id)">Accept</a></td>
				      </tr>
				    </tbody>
				  </table>

            </div>

        </div>
    </section>

    <section id="inner-page">
        <div class="container">
            <h1>My Pickups</h1>

            <div class="row">

            	 <table class="table table-hover">
					    <thead>
					      <tr>
					        <th>Name</th>
					        <th>Contact</th>
					        <th>Pickup Date</th>
					        <th>Pickup Time</th>
					        <th>Request Date</th>
					        <th>Details</th>
					      </tr>
					    </thead>
				    <tbody>
				      <tr  *ngFor="let p of pickups"> 
				        <td>{{p.Name}}</td>
				        <td>{{p.Contact}}</td>
				        <td>{{p.Date | date : "dd MMM y" }}</td>
				        <td>{{p.Time}}</td>
				        <td>{{p.CreatedAt | date : "dd MMM y" }}</td>
				        <td><a routerLink="/pickup-details/{{p.Id}}"><i class="fa fa-eye"></i></a></td>
				      </tr>
				    </tbody>
				  </table>

            </div>

        </div>
    </section>

     <section id="inner-page" style="display: none;">
        <div class="container">
            <h1>My Services</h1>

            <div class="row">

            	 <table class="table table-hover">
					    <thead>
					      <tr>
					        <th>Name</th>
					        <th>Contact</th>
					        <th>Service Date</th>
					        <th>Service Time</th>
					        <th>Request Date</th>
					        <th>Details</th>
					      </tr>
					    </thead>
				    <tbody>
				      <tr  *ngFor="let p of services"> 
				        <td>{{p.Name}}</td>
				        <td>{{p.Contact}}</td>
				        <td>{{p.Date | date : "dd MMM y" }}</td>
				        <td>{{p.Time}}</td>
				        <td>{{p.CreatedAt | date : "dd MMM y" }}</td>
				        <td><a routerLink="/service-details/{{p.Id}}"><i class="fa fa-eye"></i></a></td>
				      </tr>
				    </tbody>
				  </table>

            </div>

        </div>
    </section>


    
    <div class="clearfix"></div>