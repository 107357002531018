
    <section id="donation-list">

        <div class="container">
            <div class="text-center">
                <h1>Contact Us</h1>
            </div>
            <div class="row">
                <div class="col-md-7 col-sm-7">
                    <div class="contact-blk">
                        <div class="row">
                            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" id="contactForm">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" placeholder="Name" class="form-control" formControlName="name" required="">
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">Name is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" placeholder="Phone" class="form-control" formControlName="phone" required="">
                                         <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.phone.errors.required">Phone is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input type="email" placeholder="Email" class="form-control" formControlName="email" required="">
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                            <div *ngIf="f.email.errors.email">Invalid email</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Subject" formControlName="subject" required="">
                                        <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                            <div *ngIf="f.subject.errors.required">Subject is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <textarea rows="4" placeholder="Messages" class="form-control" formControlName="message" required=""></textarea>
                                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                            <div *ngIf="f.message.errors.required">Message is required</div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input type="submit" value="Send" name="" type="submit" class="next-btn hvr-sweep-to-right login">
                                    </div>
                                </div>
                            </form>
                            <p class="text-center" id="thanks" hidden="">
                                Thanks for contacting us!
                            </p>
                            </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-5">
                    <div class="address-blk">
                        <h5>Address</h5>
                        <p>Dwarka, Nashik, Maharashtra 422001</p>
                    </div>
                    <div class="address-blk">
                        <h5>Phone</h5>
                        <p>+91 9851 192 192</p>
                    </div>
                    <div class="address-blk">
                        <h5>Email Id</h5>
                        <a href="mailto:support@bhangarwale.in">support@bhangarwale.in</a>
                    </div>

                </div>
            </div>
        </div>
    </section>

    <!-- donation section ends -->

    
    <div class="clearfix"></div>