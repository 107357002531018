 <section id="inner-page">
        <div class="container">
            <h1>Sign In</h1>
            <div class="login-container">
                <div id="output"></div>
                <div class="avatar text-center"><img src="assets/images/logo.jpg" class="img-responsive img-center"></div>
                <div class="form-box">
                      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="loginForm">
                        <input type="text" placeholder="Mobile Number" formControlName="mobile" required="">
                         <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                            <div *ngIf="f.mobile.errors.required">Mobile number is required</div>
                                            <div *ngIf="f.mobile.errors.pattern">Mobile number should be 10 digit number</div>
                                        </div>

                     <input type="password" placeholder="Password" formControlName="password" required="">
                         
                        
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                        </div> 

                       <!--  <input class="pass-input-sm" type="password" placeholder="Password" formControlName="password" required="">
                         
                        <a href="#" id="useotpbtn" class="send-btn" type="button">Send OTP</a>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback pass-input-sm">
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                        </div> -->
                          <p id="error"  class="form-error">{{error}}</p>
                        <!-- <button class="next-btn hvr-sweep-to-right login" type="submit">Login</button> -->
                         <input type="submit" value="Login" name="" type="submit" class="next-btn hvr-sweep-to-right login">
                        <div class="panel-footer">
                            <span class="text-muted"><span class="p-r-xs">Don't have an account?</span> <a routerLink="/signup" class="btn btn-default btn-sm">Create an Account</a></span>
                        </div>
                        <p><a routerLink="/forgot-password">Forgot Password</a></p>
                    </form>
                     <p class="text-center" id="thanks" hidden="">
                                Logged In Successfully!
                            </p>
                </div>
            </div>
        </div>
    </section>

    
    <div class="clearfix"></div>