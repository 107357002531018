import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
 
 base_url = 'https://admin.bhangarwale.in/api/';

  constructor(private httpClient: HttpClient) { 
  /*
       if (location.protocol == "https:") 
       this.base_url = 'https://bhangarwale.in/admin/api/';
       else
       this.base_url = 'http://bhangarwale.in/admin/api/';
       */
  }

   public getItems(){
    return this.httpClient.get(this.base_url+'getItems');
  }

  public getTestimonials(){
    return this.httpClient.get(this.base_url+'getTestimonials');
  }


  public getCities(){
    return this.httpClient.get(this.base_url+'getCities');
  }

  public getLocalitiesByCity(cityid){
    return this.httpClient.get(this.base_url+'getLocalitiesByCity/'+cityid);
  }

  public getUserInfo(userid){
    return this.httpClient.get(this.base_url+'getUserInfo/'+userid);
  }

  public getUserPickups(userid){
    return this.httpClient.get(this.base_url+'getUserPickups/'+userid);
  }

  public getPickupDetails(id){
    return this.httpClient.get(this.base_url+'getPickup/'+id);
  }

  public getUserServices(userid){
    return this.httpClient.get(this.base_url+'getUserServices/'+userid);
  }

  public getServiceDetails(id){
    return this.httpClient.get(this.base_url+'getService/'+id);
  }
  public getVendorPickups(userid){
    return this.httpClient.get(this.base_url+'getVendorPickups/'+userid);
  }

  public getVendorServices(userid){
    return this.httpClient.get(this.base_url+'getVendorServices/'+userid);
  }

  public getOpenPickupRequests(localityid){
    return this.httpClient.get(this.base_url+'getOpenPickupRequests/'+localityid);
  }

  public getOpenServiceRequets(localityid){
    return this.httpClient.get(this.base_url+'getOpenServiceRequets/'+localityid);
  }


  public getItemsByCategory(){
    return this.httpClient.get(this.base_url+'getItemsBycategory');
  }

   public getServicesByCategory(){
    return this.httpClient.get(this.base_url+'getServicesBycategory');
  }

   public postContact(formData){


   const formData1 = new FormData();

   formData1.append('name', formData.name);
   formData1.append('email', formData.email);
   formData1.append('subject', formData.subject);
   formData1.append('message', formData.message);
   formData1.append('phone', formData.phone);

           // let headers = new Headers({ 'Content-Type': 'application/json' });

          //  headers.append('Access-Control-Allow-Origin','*');

          //  let options = new RequestOptions({ headers:headers });

    return this.httpClient.post(this.base_url+'postContact',formData1);
  }

    public register(formData){


   const formData1 = new FormData();

   formData1.append('name', formData.name);
   formData1.append('email', formData.email);
   formData1.append('mobile', formData.mobile);
   formData1.append('password', formData.password);
   formData1.append('city', formData.city);
   formData1.append('locality', formData.locality);
   formData1.append('landmark', formData.landmark);
   formData1.append('address', formData.address);
   formData1.append('cityid', formData.cityid);
   formData1.append('localityid', formData.localityid);

    return this.httpClient.post(this.base_url+'register',formData1);
  }

   public updateProfile(formData){


   const formData1 = new FormData();

   formData1.append('name', formData.name);
   formData1.append('city', formData.city);
   formData1.append('locality', formData.locality);
   formData1.append('landmark', formData.landmark);
   formData1.append('address', formData.address);
   formData1.append('userid', formData.userid);
   formData1.append('cityid', formData.cityid);
   formData1.append('localityid', formData.localityid);

    return this.httpClient.post(this.base_url+'updateProfile',formData1);
  }

  public updatePassword(formData){


   const formData1 = new FormData();
   formData1.append('oldpassword', formData.oldpassword);
   formData1.append('password', formData.password);
   formData1.append('userid', formData.userid);

    return this.httpClient.post(this.base_url+'changePassword',formData1);
  }

  public forgotPassword(formData){


   const formData1 = new FormData();

   formData1.append('email', formData.email);

    return this.httpClient.post(this.base_url+'forgotPassword',formData1);
  }

    public resetPassword(formData){

   const formData1 = new FormData();

   formData1.append('code', formData.code);
   formData1.append('email', formData.email);
    formData1.append('password', formData.password);

    return this.httpClient.post(this.base_url+'resetPassword',formData1);
  }


    public login(formData){


   const formData1 = new FormData();

   formData1.append('mobile', formData.mobile);
   formData1.append('password', formData.password);

    return this.httpClient.post(this.base_url+'login',formData1);
  }

  public validateMobileno(formData){


   const formData1 = new FormData();

   formData1.append('mobile', formData.mobile);
   formData1.append('type', formData.type);

    return this.httpClient.post(this.base_url+'validateMobileno',formData1);
  }

  public saveAddress(formData){

   const formData1 = new FormData();

   formData1.append('name', formData.name);
   formData1.append('requestid', formData.requestid);
   formData1.append('userid', formData.userid);
   formData1.append('newuser', formData.newuser);
   formData1.append('contact', formData.contact);
   formData1.append('email', formData.email);
   formData1.append('city', formData.city);
   formData1.append('locality', formData.locality);
   formData1.append('landmark', formData.landmark);
   formData1.append('address', formData.address);
   formData1.append('type', formData.type);
   formData1.append('cityid', formData.cityid);
   formData1.append('localityid', formData.localityid);

    return this.httpClient.post(this.base_url+'saveAddress',formData1);
  }


  public requestPickup(formData){

   const formData1 = new FormData();

   formData1.append('requestid', formData.requestid);
   formData1.append('items', formData.items);
   formData1.append('date', formData.date);
   formData1.append('time', formData.time);
   formData1.append('type', formData.type);

    return this.httpClient.post(this.base_url+'requestPickup',formData1);
  }

  public acceptRequest(type,id){

   const formData1 = new FormData();

   formData1.append('type', type);
   formData1.append('id', id);
   formData1.append('vendorid',localStorage.getItem('id'));

    return this.httpClient.post(this.base_url+'acceptRequest',formData1);
  }

   public updateRequest(formData){

    const formData1 = new FormData();

   
   formData1.append('type', formData.type);
   formData1.append('id', formData.id);
   formData1.append('amount',formData.amount);
   formData1.append('totalamount',formData.totalamount);
   formData1.append('items',formData.items);
   formData1.append('weight',formData.weight);

    return this.httpClient.post(this.base_url+'updateRequest',formData1);
  }


   public rateVendor(formData){

    const formData1 = new FormData();

   
   formData1.append('type', formData.type);
   formData1.append('id', formData.id);
   formData1.append('userid',formData.userid);
   formData1.append('vendorid',formData.vendorid);
   formData1.append('rating',formData.rating);
   formData1.append('review',formData.review);

    return this.httpClient.post(this.base_url+'rateVendor',formData1);
  }

}
