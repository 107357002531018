import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators,FormBuilder } from '@angular/forms';
import { ApiService } from '../../api.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
 
  cities;
  localities;
  submitted = false;
  error;
  registerForm = this.fb.group({
	  name: ['', [Validators.required,Validators.pattern("^[a-zA-Z ]*$")]],
	  mobile: ['', [Validators.required,Validators.pattern("^[0-9]{10}$")]],
	  email: ['', [Validators.required,Validators.email]],
	  password: ['', [Validators.required,Validators.minLength(6)]],
	  cpassword: ['', [Validators.required]],
	  city: ['', [Validators.required]],
	  locality: ['', [Validators.required]],
	  landmark: ['', [Validators.required]],
	  address: ['', [Validators.required]],
    cityid: ['', [Validators.required]],
    localityid: ['', [Validators.required]],
	});

  constructor(private fb: FormBuilder,private apiService: ApiService) { }

  ngOnInit() {

    this.apiService.getCities().subscribe((data)=>{
      //console.log(data);
       this.cities = data;

        this.registerForm.controls.cityid.setValue(1);
           this.registerForm.controls.city.setValue('Nashik');

            this.apiService.getLocalitiesByCity(1).subscribe((data)=>{
            //console.log(data);
             this.localities = data;
          });
    });
  }

  cityChanged(event: Event)
  {

   let selectedOptions = event.target['options'];
   let selectedIndex = selectedOptions.selectedIndex;
   let selectElementText = selectedOptions[selectedIndex].text;
   this.registerForm.controls.city.setValue(selectElementText);
    this.apiService.getLocalitiesByCity(this.registerForm.value.cityid).subscribe((data)=>{
      //console.log(data);
       this.localities = data;
    });

  }

  localityChanged(event: Event)
  {

   let selectedOptions = event.target['options'];
   let selectedIndex = selectedOptions.selectedIndex;
   let selectElementText = selectedOptions[selectedIndex].text;
   this.registerForm.controls.locality.setValue(selectElementText);
  }


  get f() { return this.registerForm.controls; }

  onSubmit() {
   
     this.error  = ''; 
   document.getElementById("thanks").style.display = "none";

   this.submitted = true;
	// TODO: Use EventEmitter with form value
	//console.warn(this.registerForm.value);

	 if (this.registerForm.invalid) {
            return;
        }

    if(this.registerForm.value.password !== this.registerForm.value.cpassword)
    {
       this.error = 'Password and confirm password should be same';
       return; 
    }





	this.apiService.register(this.registerForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        this.submitted = false;
        //$('#contactForm').hide();
        this.registerForm.reset();
        document.getElementById("thanks").style.display = "block"; 
      }
      if(data.status == false)
      {
        this.submitted = true;
        //$('#contactForm').hide();
          this.error  = data.error; 
        document.getElementById("thanks").style.display = "none"; 
      }
      
    });
	}

}
