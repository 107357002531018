    <section id="inner-page">
        <div class="container">
            <h1>Change Password</h1>
            <div class="login-container">
                <div id="output"></div>
                <!-- <div class="avatar text-center"><img src="assets/images/logo.jpg" class="img-responsive img-center"></div> -->
                <div class="form-box">
                   <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" id="passwordForm">

                   	<input type="password" placeholder="Old Password" formControlName="oldpassword" required="">
                        <div *ngIf="submitted && f.oldpassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.oldpassword.errors.required">Old Password is required</div>
                                        </div>

                        
                        <input type="password" placeholder="New Password" formControlName="password" required="" minlength="6">
                         <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">New Password is required</div>
                                            <div *ngIf="f.password.errors.minlength">New Password length should be greater than 6</div>
                                        </div>

                        <input type="password" placeholder="Confirm Password" formControlName="cpassword" required="">
                        <div *ngIf="submitted && f.cpassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.cpassword.errors.required">Confirm Password is required</div>
                                        </div>

                        
                        <p id="error" class="form-error">{{error}}</p>

                    

                        <!-- <button class="next-btn hvr-sweep-to-right login" type="submit">SIGN UP</button> -->
                         <input type="submit" value="Change Password" name="" type="submit" class="next-btn hvr-sweep-to-right login">
                        
                    </form>
                    <p class="text-center" id="thanks" hidden="">
                                Password changed Successfully!
                            </p>
                </div>
            </div>
        </div>
    </section>

    
    <div class="clearfix"></div>