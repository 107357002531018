import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators,FormBuilder } from '@angular/forms';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  submitted = false;
  error;
  passwordForm = this.fb.group({
	  email: ['', [Validators.required,Validators.email]]
	});

  constructor(private fb: FormBuilder,private apiService: ApiService) { }

  ngOnInit() {
  }
    get f() { return this.passwordForm.controls; }

  onSubmit() {
   
    this.error  = ''; 
   document.getElementById("thanks").style.display = "none";

   this.submitted = true;
	// TODO: Use EventEmitter with form value
	//console.warn(this.passwordForm.value);

	 if (this.passwordForm.invalid) {
            return;
        }


	this.apiService.forgotPassword(this.passwordForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        this.submitted = false;
        //$('#contactForm').hide();
        this.passwordForm.reset();
        document.getElementById("thanks").style.display = "block"; 
      }
      if(data.status == false)
      {
        this.submitted = true;
        //$('#contactForm').hide();
          this.error  = data.error; 
        document.getElementById("thanks").style.display = "none"; 
      }
      
    });
	}
}
