import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private meta: Meta,private router: Router) { 
   this.meta.updateTag({ name: 'keywords', content: 'bhangarwale,waste management,recycle waste,paper waste,plastic waste,e-waste,save environment' });
  }

  ngOnInit() {
  
  if(localStorage.getItem("isLoggedIn") === null)
     localStorage.setItem('isLoggedIn','false');
  }

  logout()
  {

    localStorage.clear();
    localStorage.setItem('isLoggedIn','false');
     this.router.navigate(['/']);
  }

  isLoggedIn()
  {
    return localStorage.getItem('isLoggedIn');
  }

  getUserType()
  {
    return localStorage.getItem('usertype');
  }

}
