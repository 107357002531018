import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators,FormBuilder } from '@angular/forms';
import { ApiService } from '../../api.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  
   cities;
  localities;
  submitted = false;
  error;
  userid = localStorage.getItem('id');
  profileForm = this.fb.group({
	  name: ['', [Validators.required]],
	  mobile: ['', [Validators.required,Validators.pattern("^[0-9]{10}$")]],
	  email: ['', [Validators.required,Validators.email]],
	  city: ['', [Validators.required]],
	  locality: ['', [Validators.required]],
	  landmark: ['', [Validators.required]],
	  address: ['', [Validators.required]],
	  userid : [''],
    cityid: ['', [Validators.required]],
    localityid: ['', [Validators.required]],
	});

  constructor(private fb: FormBuilder,private apiService: ApiService) { }

  ngOnInit() {

 
  	this.apiService.getUserInfo(this.userid).subscribe((data:any)=>{

       this.profileForm.setValue({name: data.Name,mobile: data.Mobile,city:data.City,locality:data.Locality,cityid:data.CityId,localityid:data.LocalityId,landmark:data.Landmark,address: data.Address,userid:data.Id,email:data.Email});

        this.apiService.getLocalitiesByCity(data.CityId).subscribe((data)=>{
          //console.log(data);
           this.localities = data;
        });
    });

      this.apiService.getCities().subscribe((data)=>{
      //console.log(data);
       this.cities = data;
    });

   

  }

  cityChanged(event: Event)
  {

   let selectedOptions = event.target['options'];
   let selectedIndex = selectedOptions.selectedIndex;
   let selectElementText = selectedOptions[selectedIndex].text;
   this.profileForm.controls.city.setValue(selectElementText);
    this.apiService.getLocalitiesByCity(this.profileForm.value.cityid).subscribe((data)=>{
      //console.log(data);
       this.localities = data;
    });

  }

  localityChanged(event: Event)
  {

   let selectedOptions = event.target['options'];
   let selectedIndex = selectedOptions.selectedIndex;
   let selectElementText = selectedOptions[selectedIndex].text;
   this.profileForm.controls.locality.setValue(selectElementText);
  }

  get f() { return this.profileForm.controls; }

  onSubmit() {
   
   this.error  = ''; 
   document.getElementById("thanks").style.display = "none";

   this.submitted = true;
	// TODO: Use EventEmitter with form value
	//console.warn(this.profileForm.value);

	if (this.profileForm.invalid) {
            return;
    }


	this.apiService.updateProfile(this.profileForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        this.submitted = false;
        localStorage.setItem('name', this.profileForm.value.name);
        localStorage.setItem('localityid', this.profileForm.value.localityid);
        document.getElementById("thanks").style.display = "block"; 
      }
      if(data.status == false)
      {
        this.submitted = true;
        this.error  = data.error; 
        document.getElementById("thanks").style.display = "none"; 
      }
      
    });
	}

}
