import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl,Validators,FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit {

  service;
  id;
  submitted = false;
  error;
   amountForm = this.fb.group({
    totalamount: [''],
    weight : [''],
    amount: [''],
    items: [''],
    type: ['service'],
    id : [],
  });

    reviewForm = this.fb.group({
    rating: [3],
    review: [''],
    userid: [''],
    vendorid: [''],
    type: ['service'],
    id : [],
  });


  constructor(private fb: FormBuilder,private apiService: ApiService,private route: ActivatedRoute) { }

  ngOnInit() {
   
    this.id = this.route.snapshot.paramMap.get("id");
     this.apiService.getServiceDetails(this.id).subscribe((data:any)=>{
      //console.log(data);
       this.service = data;
        this.amountForm.controls.id.setValue(data.Id);
           this.reviewForm.controls.id.setValue(data.Id);
          this.reviewForm.controls.userid.setValue(data.UserId);
          this.reviewForm.controls.vendorid.setValue(data.VendorId);
    });

  }


  getUserType()
  {
    return localStorage.getItem('usertype');
  }

    ratingChange(value)
  {
    this.reviewForm.controls.rating.setValue(value);
  }


   amountChange()
  {

    
         var total = 0;
        var amountlength = document.getElementsByName("amount");
          var amount = [];

         for (var i=0; i<amountlength.length; i++) {
            var inputValue = (<HTMLInputElement>document.getElementById(amountlength[i].id)).value;
            if(inputValue == '')
            {
               this.error  = 'all fields are required'; 
                return;
            }
            var amt = Number(inputValue);
            if(amt > 0)
            {
              amount.push(inputValue);
              total = total + amt;
            }
            else
            {
              this.error  = 'amount should be number'; 
              return;
            }
         }

          this.amountForm.controls.totalamount.setValue(total);
           this.error  = '';


  }

  get f() { return this.amountForm.controls; }

   onSubmit() {
   
   this.error  = ''; 

   this.submitted = true;

   
     var total = 0;
        var amountlength = document.getElementsByName("amount");
          var amount = [];

         for (var i=0; i<amountlength.length; i++) {
            var inputValue = (<HTMLInputElement>document.getElementById(amountlength[i].id)).value;
            if(inputValue == '')
            {
               this.error  = 'all fields are required'; 
                return;
            }
            var amt = Number(inputValue);
            if(amt > 0)
            {
              amount.push(inputValue);
              total = total + amt;
            }
            else
            {
              this.error  = 'amount should be number'; 
              return;
            }
         }


          this.amountForm.controls.totalamount.setValue(total);

          var itemslength = document.getElementsByName("item");
          var items = [];

         for (var i=0; i<itemslength.length; i++) {
            var inputValue = (<HTMLInputElement>document.getElementById(itemslength[i].id)).value;
            items.push(inputValue);
         }

          this.amountForm.controls.amount.setValue(amount.toString());
          this.amountForm.controls.items.setValue(items.toString());

  console.log(this.amountForm.value);
  //return;

   this.error  = '';

  this.apiService.updateRequest(this.amountForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        location.reload();
      }
      if(data.status == false)
      {
        this.submitted = true;
        this.error  = data.error; 
      }
      
    });
  }

    onSubmit1() {
  
  this.apiService.rateVendor(this.reviewForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        location.reload();
      }
      if(data.status == false)
      {
        this.error  = data.error; 
      }
      
    });
  }

}
