<section id="welcome">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-4 wow fadeInLeft animated" data-wow-delay="0.3s">
                    <div class="welcome-img">

                        <h5 class="section-sub-title">Welcome to </h5>
                        <h1>Bhangarwale !</h1>
                        <a routerLink="/about" class="next-btn hvr-sweep-to-right hidden-xs">
                            <span>More Information</span>
                        </a> 
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 wow fadeInRight animated" data-wow-delay="0.3s">
                    <p><strong> Every day we encounter hundreds of recyclable items. By recycling properly, you help materials get to their next best use, which in turn saves tons upon tons of raw material, time, energy and expense.</strong></p>

                    <p>According to our surveys, we generate as much as 1200 grams of paper and 1.6KG of plastic waste a month per single household! Imagine the number of trees being cut down and the number of land fills being dug for the same - staggering!</p>

                    <a routerLink="/about" class="next-btn hvr-sweep-to-right visible-xs">
                            <span>More Information</span>
                    </a>

                </div>
                <div class="col-md-2 col-sm-2 wow fadeInRight animated" data-wow-delay="0.3s">
                    <img src="assets/images/welcome-right-img.png" class="img-responsive">
                </div>
            </div>

        </div>
    </section>

    <section id="process">
        <div class="container">
            <h1>Work Process</h1>
            <div class="row">
                <div class="col-md-4 col-sm-4 blk wow fadeInLeft animated" data-wow-delay="0.3s">
                    <div class="process-blk"><span class="icon-pickup"></span> <span class="cound">1</span> </div>
                    <div class="process-shor-details">

                        <h2>Home Pickups</h2>
                        <p>As per the scheduled date and time, our nearest Bhangarwale-Agent will hit your doorsteps.</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 blk wow fadeInLeft animated" data-wow-delay="0.6s">
                    <div class="process-blk"><span class="icon-policy"></span> <span class="cound">2</span> </div>
                    <div class="process-shor-details">
                        <h2>Govt. certified scale</h2>
                        <p>The Bhangarwale-Agent then weights the scrap or recyclable waste digitally and fulfills your request.</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 blk wow fadeInLeft animated" data-wow-delay="0.9s">
                    <div class="process-blk"><span class="icon-bill"></span> <span class="cound">3</span> </div>
                    <div class="process-shor-details">
                        <h2>Instant cash billing</h2>
                        <p>You will instantly receive monetary returns once your request has been completed successfully.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

   <section id="price-list">
        <div class="container">
            <h1>Items We Pick Up</h1>
            <div id="owl-demo" class="owl-carousel owl-theme">
              <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/newspeppar.jpg">
                    <strong>Newspaper <!-- 8 Rs / KG* --></strong>
                </a>
            </div>
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/book.jpg">
                    <strong>Books <!-- 8 Rs / KG* --></strong>
                </a>
            </div>
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/magzine.jpg">
                    <strong>Magzines <!-- 7 Rs / KG* --></strong>
                </a>
            </div>
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/cartons.jpg">
                    <strong>Cartons <!-- 5 Rs / KG* --></strong>
                </a>
            </div>
            <!-- <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/milk.jpg">
                    <strong>Milk Packs 6 Rs / KG*</strong>
                </a>
            </div> -->
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/plastic.jpg">
                    <strong>Mix Plastics <!-- 7 Rs / KG* --></strong>

                </a>
            </div>
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/beer-bottes.jpg">
                    <strong>Beer Bottles <!-- 1 Rs / KG* --></strong>
                </a>
            </div>
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/peper.jpg">
                    <strong>Paper <!-- 1 Rs / KG* --></strong>
                </a>
            </div>
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/iron.jpg">
                    <strong>Iron <!-- 16 Rs / KG* --></strong>
                </a>
            </div>
            <!-- <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/copper.jpg">
                    <strong>Copper 300 Rs / KG*</strong>
                </a>
            </div> -->
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/steel.jpg">
                    <strong>Steel <!-- 20 Rs / KG* --></strong>
                </a>
            </div>
            <!-- <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/tin.jpg">
                    <strong>Tin 10 Rs / KG*</strong>
                </a>
            </div> -->
            <!-- <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/brass.jpg">
                    <strong>Brass 200 Rs / KG*</strong>
                </a>
            </div> -->
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/plastic-bottles.jpg">
                    <strong>Plastic Bottles <!-- 10 Rs / KG* --></strong>
                </a>
            </div>
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/battery.jpg">
                    <strong>Battery <!-- 35 Rs / KG* --></strong>
                </a>
            </div>
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/e-wasete.jpg">
                    <strong>E-Waste <!-- 12 Rs / KG* --></strong>
                </a>
            </div>
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/toy.jpg">
                    <strong>Toys <!-- 12 Rs / KG* --></strong>
                </a>
            </div>
            <div class="item">
                <a class="thumbnail"><img alt="" src="assets/images/cycle.jpg">
                    <strong>Cycle <!-- 12 Rs / KG* --></strong>
                </a>
            </div>
            </div>
            <div class="center">
               <a routerLink="/price-list" class="next-btn hvr-sweep-to-right hidden-xs">
                    <span>More Information</span>
                </a> 
            </div>
        </div>
    </section>


    <section id="environment-save">
        <div class="container">
            <h2> <strong> The Bhangarwale towards the Environment </strong> </h2>
            <h3> Registered users reached over 40 K & their contribution </h3>
            <h4> Counting down ... </h4>
            <div class="sections">
            <div class="col-sm-3 col-xs-6 save-trees">
                    <img src="assets/images/save-trees.png">
                    <p> Tree Saved </p> 
                    <p> 10243 </p>
              
            </div>

             <div class="col-sm-3 col-xs-6 save-trees">
                    <img src="assets/images/save-electricity.png">
                    <p> Energy Saved </p> 
                    <p> 565536 Kwh </p>
              
            </div>

             <div class="col-sm-3 col-xs-6 save-trees">
                    <img src="assets/images/save-water.png">
                    <p> Water Saved </p> 
                    <p> 13892652 ltr </p>
              
            </div>

              <div class="col-sm-3 save-trees">
                    <img src="assets/images/save-fuel.png">
                    <p> Oil Saved </p> 
                    <p> 253278 ltr </p>
              
            </div>

        </div>
        </div>
        
    </section>


    <!-- testimonials -->
<section id="testimonials">
    <div class="container">
         <h1>Testimonials</h1>
    <div id="owl-demo3" class="owl-carousel owl-theme">
    <div class="item">
              <div class="testimonial-item tstyle-border2">
                                
                        <div class="testimonial-content">
                            <p>Bhangarwale.in have helped us to remove all the unwanted materials from our office. Their service is the best in the industry! I would highly recommend them to remove all the clutter from your office or factory.</p>

                        </div>

                        <div class="author-info-box">
                            <div class="author-details"><a>Subodh Mahant</a>
                                <p class="author-designation-info"> Gigs solutions</p>
                            </div>
                        </div>
              </div>
          </div>
          <div class="item">
              <div class="testimonial-item tstyle-border2">
                                 <div class="testimonial-content">
                            <p>Bhangarwale.in is the best thing that I have never seen before.Just collect all the scrap at one place and they will come & take it out... As simple as that.. I would highly recommend that use this service at least once..</p>

                        </div>

                        <div class="author-info-box">
                            <div class="author-details"><a>Purva Sonawane</a>
                                <p class="author-designation-info"> EnriseSpark</p>
                            </div>
                        </div>
                            </div>
          </div>
          <div class="item">
              <div class="testimonial-item tstyle-border2">
                                <div class="testimonial-content">
                            <p>Bahot acha concept hai, abhi hame bhangar ya raddy dene ke liye Bhangar wale ko dhundna padta hai, pura saman uthakar kisi bhangar wale ke yaha le jana padta hai, ab wo kam nahi karna hoga.
                            </p>

                        </div>

                        <div class="author-info-box">
                            <div class="author-details"><a>Akil Patave</a>
                                <p class="author-designation-info">Pakhal Road</p>
                            </div>
                        </div>
                            </div>
          </div>

</div>
</div>
</section>
<!-- testimonals -->
<div class="clearfix"></div>
  