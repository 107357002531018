
    <section id="inner-page">
        <div class="container">
            <h1>Pickup Details</h1>

            <div class="row">

            	 <table class="table table-hover">
				    <tbody>
				      <tr> 
				      	<td>Name : </td>
				        <td>{{pickup.Name}}</td>
				      </tr>
				      <tr> 
				      	<td>Pickups : </td>
				        <td>{{pickup.names}}</td>
				      </tr>
				      <tr> 
				      	<td>Pickup Date : </td>
				        <td>{{pickup.Date | date : "dd MMM y"}}</td>
				      </tr>
				      <tr> 
				      	<td>Pickup Time : </td>
				        <td>{{pickup.Time}}</td>
				      </tr>
				       <tr> 
				      	<td>Mobile : </td>
				        <td>{{pickup.Contact}}</td>
				      </tr>
				       <tr> 
				      	<td>City : </td>
				        <td>{{pickup.City}}</td>
				      </tr>
				       <tr> 
				      	<td>Locality : </td>
				        <td>{{pickup.Locality}}</td>
				      </tr>
				       <tr> 
				      	<td>Landmark : </td>
				        <td>{{pickup.Landmark}}</td>
				      </tr>
				      <tr> 
				      	<td>Address : </td>
				        <td>{{pickup.Address}}</td>
				      </tr>
				      <tr> 
				      	<td>Pickup Request  Date : </td>
				        <td>{{pickup.CreatedAt | date : "dd MMM y"}}</td>
				      </tr>
				       <tr> 
				      	<td>Status : </td>
				        <td>{{pickup.Status}}</td>
				      </tr>

				       <tr *ngIf="pickup.Status == 'Completed'"> 
				      	<td>Amount : </td>
				        <td>Rs. {{pickup.Amount}}</td>
				      </tr>

				       <tr *ngIf="pickup.details.length > 0"> 
				      	<td>Details : </td>
				        <td>
				        	
				        	<table>
				        		<tbody>
				        			<tr *ngFor="let i of pickup.details">
				        				<td>{{i.Category}}</td>
				        				<td>&nbsp;&nbsp;{{i.Weight}}Kg</td>
				        				<td>&nbsp;&nbsp;Rs.{{i.Amount}}</td>
				        			</tr>
				        		</tbody>
				        	</table>
				        </td>
				      </tr>

				       <tr *ngIf="pickup.review != null"> 
				      	<td>Customer Rating : </td>
				        <td> 
				        	{{pickup.review.Rating}}
				        </td>
				      </tr>

				       <tr *ngIf="pickup.review != null"> 
				      	<td>Customer Review : </td>
				        <td> 
				        	{{pickup.review.Review}}
				        </td>
				      </tr>
				        
				        
				    </tbody>
				  </table>

            </div>
            <div class="row" *ngIf="getUserType() === 'vendor' && pickup.Status == 'Pending'">
            	<button class="next-btn hvr-sweep-to-right login" data-toggle="modal" data-target="#myModal">Pickup Done</button>
            </div>

            <div class="row" *ngIf="getUserType() === 'user' && pickup.Status == 'Completed' && pickup.review == null">
            	<button class="next-btn hvr-sweep-to-right login" data-toggle="modal" data-target="#myModal1">Rate Vendor</button>
            </div>

        </div>
    </section>

    
    <div class="clearfix"></div>


    <!-- Trigger the modal with a button -->

<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Update details</h4>
      </div>
      <div class="modal-body">
      	<form [formGroup]="amountForm" (ngSubmit)="onSubmit()" id="amountForm">
        <div >
        		 <table class="table table-hover">
        		 	<thead>
        		 		<th>Item</th>
				        <th>Weight(in KG)</th>
				        <th>Amount</th>
        		 	</thead>
				    <tbody>
				      <tr *ngFor="let i of pickup.items"> 
				      	<td>{{i.Category}}<input type="hidden" id="iems{{i.Id}}" name="item" value="{{i.Id}}"></td>
				      	<td><input type="text" (change)="weightChange()" id="weight{{i.Id}}" name="weight" required=""></td>
				      	<td><input type="text" (change)="amountChange()" id="amount{{i.Id}}" name="amount" required=""></td>
				      </tr>
				      <tr>
				      	<td colspan="2">Total Amount</td>
				      	<td> <input type="text" name="" id="totalamount" formControlName="totalamount" required="" readonly="">
            			</td>
				      </tr>
				        
				    </tbody>
				  </table>
           
            <div class="error">{{error}}</div>
          </div>

         <div ><input type="submit" class="next-btn hvr-sweep-to-right" name="" value="Update">

            </div>
          </form>
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>


<div id="myModal1" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Rate Vendor</h4>
      </div>
      <div class="modal-body">
      	<form [formGroup]="reviewForm" (ngSubmit)="onSubmit1()" id="reviewForm">
 
				<div class="form-group">
					<div class="rating">
								  <label>
								    <input type="radio"  (change)="ratingChange(1)" name="stars" value="1" />
								    <span class="icon">★</span>
								  </label>
								  <label>
								    <input type="radio"  (change)="ratingChange(2)" name="stars" value="2" />
								    <span class="icon">★</span>
								    <span class="icon">★</span>
								  </label>
								  <label>
								    <input type="radio" checked=""  (change)="ratingChange(3)" name="stars" value="3" />
								    <span class="icon">★</span>
								    <span class="icon">★</span>
								    <span class="icon">★</span>   
								  </label>
								  <label>
								    <input type="radio"  (change)="ratingChange(4)" name="stars" value="4" />
								    <span class="icon">★</span>
								    <span class="icon">★</span>
								    <span class="icon">★</span>
								    <span class="icon">★</span>
								  </label>
								  <label>
								    <input type="radio" (change)="ratingChange(5)" name="stars" value="5" />
								    <span class="icon">★</span>
								    <span class="icon">★</span>
								    <span class="icon">★</span>
								    <span class="icon">★</span>
								    <span class="icon">★</span>
								  </label>
								</div>
				</div> 

				<div class="form-group">
					<textarea rows="5" formControlName="review" class="form-control"></textarea>
				</div>
		
           
            <div class="error">{{error}}</div>

         <input type="submit" class="next-btn hvr-sweep-to-right" name="" value="Submit">

            
          </form>
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>
