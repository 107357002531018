  <section id="donation-list">

        <div class="container">
            <div class="text-center">
                <h1>WE PROVIDE SERVICES TO</h1>
            </div>
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 wow rotateInDownLeft animated" data-wow-delay="0.3s">
                    <a class="thumbnail"><img alt="" src="assets/images/services1.png">
                        <strong>Homes/Apartments</strong>
                    </a>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 wow rotateInDownLeft animated" data-wow-delay="0.6s">
                    <a class="thumbnail"><img alt="" src="assets/images/services2.png">
                        <strong>Society</strong>
                    </a>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 wow rotateInDownLeft animated" data-wow-delay="0.9s">
                    <a class="thumbnail"><img alt="" src="assets/images/services3.png">
                        <strong>Educational Institute</strong>
                    </a>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 wow rotateInDownLeft animated" data-wow-delay="1.2s">
                    <a class="thumbnail"><img alt="" src="assets/images/services4.png">
                        <strong>Mall/Shop</strong>
                    </a>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 wow rotateInDownLeft animated" data-wow-delay="1.5s">
                    <a class="thumbnail"><img alt="" src="assets/images/services5.png">
                        <strong>Bank</strong>
                    </a>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 wow rotateInDownLeft animated" data-wow-delay="1.8s">
                    <a class="thumbnail"><img alt="" src="assets/images/services6.png">
                        <strong>Corporate Office</strong>

                    </a>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 wow rotateInDownLeft animated" data-wow-delay="2.1s">
                    <a class="thumbnail"><img alt="" src="assets/images/services7.png">
                        <strong>Government Offices</strong>
                    </a>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 wow rotateInDownLeft animated" data-wow-delay="2.4s">
                    <a class="thumbnail"><img alt="" src="assets/images/services8.png">
                        <strong>E-Scrap</strong>
                    </a>
                </div>
                 <div class="col-md-3 col-sm-4 col-xs-6 wow rotateInDownLeft animated" data-wow-delay="2.4s">
                    <a class="thumbnail"><img alt="" src="assets/images/services9.png">
                        <strong>Hotel/Restaurant</strong>
                    </a>
                </div>

            </div>
        </div>
    </section>

    <!-- donation section ends -->

    
    <div class="clearfix"></div>