    <section id="inner-page">
        <div class="container">
            <h1>Reset Password</h1>
            <div class="login-container">
                <div id="output"></div>
                <div class="form-box">
                   <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" id="passwordForm">
                        

                        <input type="email" placeholder="Email" formControlName="email" required="">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                            <div *ngIf="f.email.errors.email">Invalid email</div>
                                        </div>

                                           <input type="password" placeholder="New Password" formControlName="password" required="" minlength="6">
                         <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">New Password is required</div>
                                            <div *ngIf="f.password.errors.minlength">New Password length should be greater than 6</div>
                                        </div>

                        <input type="password" placeholder="Confirm Password" formControlName="cpassword" required="">
                        <div *ngIf="submitted && f.cpassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.cpassword.errors.required">Confirm Password is required</div>
                                        </div>

                                        <p  class="form-error">{{error}}</p>

                        <!-- <button class="next-btn hvr-sweep-to-right login" type="submit">SIGN UP</button> -->
                         <input type="submit" value="Reset" name="" type="submit" class="next-btn hvr-sweep-to-right login">
                    </form>
                    <p class="text-center" id="thanks" hidden="">
                                Password reset <a routerLink="/login">click here to login</a>
                            </p>
                </div>
            </div>
        </div>
    </section>

    
    <div class="clearfix"></div>