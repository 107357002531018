import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators,FormBuilder } from '@angular/forms';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
	
  submitted = false;
  contactForm = this.fb.group({
	  name: ['', [Validators.required]],
	  phone: ['', [Validators.required]],
	  email: ['', [Validators.required,Validators.email]],
	  subject: ['', [Validators.required]],
	  message: ['', [Validators.required]],
	});

  constructor(private fb: FormBuilder,private apiService: ApiService) { }

  ngOnInit() {
  }

   get f() { return this.contactForm.controls; }

  onSubmit() {

  document.getElementById("thanks").style.display = "none";

   this.submitted = true;
	// TODO: Use EventEmitter with form value
	//console.warn(this.contactForm.value);

	 if (this.contactForm.invalid) {
            return;
        }


	this.apiService.postContact(this.contactForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        this.submitted = false;
        //$('#contactForm').hide();
        this.contactForm.reset();
         document.getElementById("thanks").style.display = "block"; 
      }
      
    });
	}

}
