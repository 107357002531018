import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators,FormBuilder } from '@angular/forms';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  submitted = false;
  error;
  userid = localStorage.getItem('id');
  passwordForm = this.fb.group({
	  password: ['', [Validators.required,Validators.minLength(6)]],
	  cpassword: ['', [Validators.required]],
	  userid : [''],
	  oldpassword : ['',[Validators.required]],
	  coldpassword : [''],
	});

  constructor(private fb: FormBuilder,private apiService: ApiService) { }

  ngOnInit() {

 
  	this.apiService.getUserInfo(this.userid).subscribe((data:any)=>{

       this.passwordForm.setValue({coldpassword: data.Password,oldpassword: '',password: '',cpassword:'',userid:data.Id});
    });

  }

  get f() { return this.passwordForm.controls; }

  onSubmit() {
   
   this.error  = ''; 
   document.getElementById("thanks").style.display = "none";

   this.submitted = true;
	// TODO: Use EventEmitter with form value
	//console.warn(this.passwordForm.value);

	if (this.passwordForm.invalid) {
            return;
    }

   /* if(this.passwordForm.value.oldpassword !== this.passwordForm.value.coldpassword)
    {
       this.error = 'Incorrect old password';
       return; 
    }

    */

    if(this.passwordForm.value.password !== this.passwordForm.value.cpassword)
    {
       this.error = 'Password and confirm password should be same';
       return; 
    }




	this.apiService.updatePassword(this.passwordForm.value).subscribe((data:any)=>{
      if(data.status == true)
      {
        this.submitted = false;
        this.passwordForm.reset();
        document.getElementById("thanks").style.display = "block"; 
      }
      if(data.status == false)
      {
        this.submitted = true;
          this.error  = data.error; 
        document.getElementById("thanks").style.display = "none"; 
      }
      
    });
	}

}
