import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PriceListComponent } from './pages/price-list/price-list.component';
import { RequestComponent } from './pages/request/request.component';
import { ShopComponent } from './pages/shop/shop.component';
import { ServicesComponent } from './pages/services/services.component';
import { AboutComponent } from './pages/about/about.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { TermsConditionComponent } from './pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { PickupDetailsComponent } from './pages/pickup-details/pickup-details.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { RequestServiceComponent } from './pages/request-service/request-service.component';
import { ServiceDetailsComponent } from './pages/service-details/service-details.component';

const routes: Routes = [
{
	path: '',
	component: HomeComponent,
	data: { title: 'Home' }
},
{
	path: 'home',
	component: HomeComponent,
	data: { title: 'Home' }
},
{
	path: 'price-list',
	component: PriceListComponent,
	data: { title: 'Price List' }
},
{
	path: 'request',
	component: RequestComponent,
	data: { title: 'Request Pickup' }
},
{
	path: 'request-service',
	component: RequestServiceComponent,
	data: { title: 'Request Service' }
},
{
	path: 'shop',
	component: ShopComponent,
	data: { title: 'Shop' }
},
{
	path: 'services',
	component: ServicesComponent,
	data: { title: 'Services' }
},
{
	path: 'about',
	component: AboutComponent,
	data: { title: 'About' }
},
{
	path: 'login',
	component: LoginComponent,
	data: { title: 'Login' }
},
{
	path: 'signup',
	component: SignupComponent,
	data: { title: 'Sign Up' }
},
{
	path: 'terms-condition',
	component: TermsConditionComponent,
	data: { title: 'Terms and conditions' }
},
{
	path: 'privacy-policy',
	component: PrivacyPolicyComponent,
	data: { title: 'Privacy Policy' }
},
{
	path: 'contact',
	component: ContactComponent,
	data: { title: 'Contact' }
},
{
	path: 'dashboard',
	component: DashboardComponent,
	data: { title: 'Dashboard' }
},
{
	path: 'profile',
	component: ProfileComponent,
	data: { title: 'Profile' }
},
{
	path: 'change-password',
	component: ChangePasswordComponent,
	data: { title: 'Change Password' }
},
{
	path: 'forgot-password',
	component: ForgotPasswordComponent,
	data: { title: 'Forgot Password' }
},
{
	path: 'reset-password/:code',
	component: ResetPasswordComponent,
	data: { title: 'Reset Password' }
},
{
	path: 'pickup-details/:id',
	component: PickupDetailsComponent,
	data: { title: 'Pickup Details' }
},
{
	path: 'service-details/:id',
	component: ServiceDetailsComponent,
	data: { title: 'Service Details' }
},
{
	path: 'thank-you',
	component: ThankYouComponent,
	data: { title: 'Thank You' }
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
