import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UpdateService } from './update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bhangarwale';
  constructor(private router: Router,private sw: UpdateService ) {

   // check the service worker for updates
    this.sw.checkForUpdates();

}

  onActivate(event) {
    window.scroll(0,0);
    //document.getElementById("navbar").style.display = "none"; 
    //document.getElementById("toggle-button").click();
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)



    var url =  this.router.url;
    //var res = url.split("/");
    //console.log(url);
    if(url == '/' || url == '/about' || url == '/terms-condition' || url == '/privacy-policy' || url == '/price-list')
    {
       document.getElementById("why").style.display = "block"; 
    }
    else
    {
       document.getElementById("why").style.display = "none"; 
    }
  
   
}
}
