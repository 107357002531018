
    <section id="inner-page">
        <div class="container">
            <h1>Price List</h1>

            <div class="row">

                <!-- <div *ngFor="let item of items" class="col-md-3 wow rotateInDownLeft animated"  data-wow-delay="{{0.3 * $index}}s"> -->
                <div *ngFor="let item of items" class="col-md-3 wow rotateInDownLeft animated">
                  <a class="thumbnail"><img alt="" src="{{item.Image}}">
                        <strong  *ngIf="item.Unit !== 'Donate' && item.Price > 0">{{item.Title}} - Rs.{{item.Price}}/{{item.Unit}}*</strong>
                         <strong  *ngIf="item.Unit !== 'Donate' && item.Price == 0">{{item.Title}} - Contact Us*</strong>
                        <strong  *ngIf="item.Unit == 'Donate'">{{item.Title}} - {{item.Unit}}</strong>
                    </a>
                </div>

              <!--   <div ng-repeat="item in items" class="col-md-3 wow rotateInDownLeft animated"  data-wow-delay="{{0.3 * $index}}s">
                    <a class="thumbnail" href="#"><img alt="" src="http://localhost/bhangarwale/assets/images/{{item.Image}}">
                        <strong>{{item.Title}} {{item.Price}} Rs / {{item.Unit}}*</strong>
                    </a>
                </div> -->

               <!--  <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="0.3s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/newspeppar.jpg">
                        <strong>Newspaper 8 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="0.6s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/book.jpg">
                        <strong>Books 8 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="0.9s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/magzine.jpg">
                        <strong>Magzines 7 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="1.2s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/cartons.jpg">
                        <strong>Cartons 5 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="1.5s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/milk.jpg">
                        <strong>Milk Packs 6 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="1.8s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/plastic.jpg">
                        <strong>Mix Plastics 7 Rs / KG*</strong>

                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="2.1s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/beer-bottes.jpg">
                        <strong>Beer Bottles 1 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="2.4s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/peper.jpg">
                        <strong>Paper 1 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="2.7s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/iron.jpg">
                        <strong>Iron 16 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="3.0s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/copper.jpg">
                        <strong>Copper 300 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="3.3s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/steel.jpg">
                        <strong>Steel 20 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="3.6s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/tin.jpg">
                        <strong>Tin 10 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="3.9s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/brass.jpg">
                        <strong>Brass 200 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="3.12s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/plastic-bottles.jpg">
                        <strong>Plastic Bottles 10 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="3.15s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/battery.jpg">
                        <strong>Battery 35 Rs / KG*</strong>
                    </a>
                </div>
                <div class="col-md-3 wow rotateInDownLeft animated" data-wow-delay="3.18s">
                    <a class="thumbnail" href="#"><img alt="" src="assets/images/e-wasete.jpg">
                        <strong>E-Waste 12 Rs / KG*</strong>
                    </a>
                </div> -->

            </div>

        </div>
    </section>
