    <section id="inner-page">
        <div class="container">
            <h1>Request Service</h1>
            <div class="login-container requestPage">
                <div id="output"></div>
                <div class="avatar text-center"><img src="assets/images/logo.jpg" class="img-responsive img-center"></div>
                <div class="form-box">
                    
                        <section>
                            <div class="wizard">
                                <div class="wizard-inner">
                                    <div class="connecting-line"></div>
                                    <ul class="nav nav-tabs" role="tablist">

                                        <li role="presentation" id="tab1" class="active">
                                            <a  data-toggle="tab" aria-controls="step1" role="tab" title="" data-original-title="Step 1">
                                                <span class="round-tab">
                                <i class="fa fa-phone" aria-hidden="true"></i>
                            </span>
                                                <span class="step">Mobile No</span>
                                            </a>
                                        </li>

                                    <li role="presentation" id="tab2" class="disabled">
                                            <a  data-toggle="tab" aria-controls="step2" role="tab" title="" data-original-title="Step 2">
                                                <span class="round-tab">
                                <i class="fa fa-map-marker"></i>
                            </span>
                                                <span class="step">Address</span>
                                            </a>
                                        </li>
                                        <li role="presentation" id="tab3" class="disabled">
                                            <a data-toggle="tab" aria-controls="step3" role="tab" title="" data-original-title="Step 3">
                                                <span class="round-tab">
                                <i class="fa fa-truck"></i>
                            </span>
                                                <span class="step">Pickup</span>
                                            </a>
                                        </li>

                                    </ul>
                                </div>


   <div class="tab-content">

     <div class="tab-pane active" role="tabpanel" id="step1">
       <h3>Mobile Number</h3>
        <div class="scroll">
         <form [formGroup]="mobilenoForm" (ngSubmit)="onSubmit1()" id="mobilenoForm">
        <div >
            <input *ngIf="isLoggedIn() === 'false'" class="input-sm" type="text" name="" placeholder="Mobile Number"formControlName="mobile" required="">
            <input *ngIf="isLoggedIn() === 'true'" class="input-sm" type="text" name="" placeholder="Mobile Number"formControlName="mobile" required="" readonly="">
                         <div *ngIf="submitted1 && f1.mobile.errors" class="invalid-feedback">
                                            <div *ngIf="f1.mobile.errors.required">Mobile number is required</div>
                                            <div *ngIf="f1.mobile.errors.pattern">Mobile number should be 10 digit number</div></div>
                                          </div>



         <div ><input type="submit" class="next-btn hvr-sweep-to-right" name="" value="Verify">

            </div>
          </form>
       </div>
        <p class="text-center" id="thanks" hidden="">
                                Your Pickup request sent Successfully!
                            </p>
     </div>
 <div class="tab-pane" role="tabpanel" id="step2">  <h3>Address</h3>
  <form [formGroup]="addressForm" (ngSubmit)="onSubmit2()" id="addressForm">
    <div class="row">
        <div class="col-md-6 col-sm-6">
            <input type="text" placeholder="Name" formControlName="name" required="">
                        <div *ngIf="submitted2 && f2.name.errors" class="invalid-feedback">
                                            <div *ngIf="f2.name.errors.required">Name is required</div>
                                        </div>
        </div>
        <div class="col-md-6 col-sm-6">
           <input type="text"  placeholder="Contact Number" formControlName="contact" required="">
            <div *ngIf="submitted2 && f2.contact.errors" class="invalid-feedback">
                                            <div *ngIf="f2.contact.errors.required">Contact number is required</div>
                                               <div *ngIf="f2.contact.errors.pattern">Contact number should be number</div>
                                        </div>
 
        </div>
       <div class="col-md-6 col-sm-6">
                                <select class="form-control" formControlName="cityid" required="" (change)="cityChanged($event)">
                                    <option value="">Select City</option>
                                    <option *ngFor="let c of cities"  value="{{c.Id}}">{{c.City}}</option>
                                </select>
                                <div *ngIf="submitted2 && f2.cityid.errors" class="invalid-feedback">
                                            <div *ngIf="f2.cityid.errors.required">City is required</div>
                                        </div>

                            </div>
                            <div class="col-md-6 col-sm-6">
                                <select class="form-control" formControlName="localityid" required="" (change)="localityChanged($event)">
                                    <option>Select Locality</option>
                                    <option *ngFor="let l of localities"  value="{{l.Id}}">{{l.Locality}}</option>
                                </select>
                                  <div *ngIf="submitted2 && f2.localityid.errors" class="invalid-feedback">
                                            <div *ngIf="f2.localityid.errors.required">Locality is required</div>
                                        </div>
                            </div>
       </div>

    <input type="text" placeholder="Enter nearest Landmark" formControlName="landmark" required="">
                        <div *ngIf="submitted2 && f2.landmark.errors" class="invalid-feedback">
                                            <div *ngIf="f2.landmark.errors.required">Landmark is required</div>
                                        </div> 

    <textarea rows="3" placeholder="Eg:- House/Flat No, Apartment Name"formControlName="address" required=""></textarea>
                          <div *ngIf="submitted2 && f2.address.errors" class="invalid-feedback">
                                            <div *ngIf="f2.address.errors.required">Address is required</div>
                                        </div>

        <input type="submit" class="next-btn hvr-sweep-to-right" name="" value="Submit">
          </form>
               </div>
   <div class="tab-pane" role="tabpanel" id="step3">
     <h3>Pickup Schedule</h3>
     <form [formGroup]="requestForm" (ngSubmit)="onSubmit3()" id="requestForm">
<div class="row pickup-section">
   <div class="col-md-3 col-sm-3" *ngFor="let c of categories">
        <h5>{{c.Category}}</h5>
        <label class="checkbox-inline" *ngFor="let i of c.items">
          <input class="pickup-box" type="checkbox" name = "item" id="item_{{i.Id}}" value="{{i.Id}}">{{i.Title}}
        </label>
    </div>
    
</div>


<div class="row">
    <div class="col-md-6 col-sm-6">
        <div class="form-group">
    <!-- <input type="date" class="form-control" name="pickupdate" required="" min="2019-07-14"> -->
                    <label class="label-control">Date</label>
                    <input type="date" class="form-control datepicker"  formControlName="date" required="" min="{{mindate}}">
                        <div *ngIf="submitted3 && f3.date.errors" class="invalid-feedback">
                                            <div *ngIf="f3.date.errors.required">Date is required</div>
                                        </div>
                </div>
    </div>
    <div class="col-md-6 col-sm-6">
         <div class="form-group">
                    <label class="label-control">Time</label>
                    <input type="time" class="form-control timepicker"  formControlName="time" required="">
                        <div *ngIf="submitted3 && f3.time.errors" class="invalid-feedback">
                                            <div *ngIf="f3.time.errors.required">Time is required</div>
                                        </div>
                </div>
    </div>
</div>
                                            
<p  class="form-error">{{error3}}</p>

                                            <p>By Clicking on request service you agree to our <a routerLink="terms-condition">Terms &amp; Conditions</a></p>
                                            <input type="submit" class="next-btn hvr-sweep-to-right" name="" value="Service Request">
</form>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                           

                            </div>
                           
                        </section>

                   
                </div>
            </div>
        </div>
    </section>

    
    <div class="clearfix"></div>
