import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  pickups;
  services;
  openpickups;
  openservices;
 userid = localStorage.getItem('id');
  constructor(private apiService: ApiService,private router: Router) { }

  ngOnInit() {

    if(localStorage.getItem('usertype')=='user')
    {
      this.apiService.getUserPickups(this.userid).subscribe((data)=>{
       // console.log(data);
        this.pickups = data;
      });

       this.apiService.getUserServices(this.userid).subscribe((data)=>{
       // console.log(data);
        this.services = data;
      });
    }

    if(localStorage.getItem('usertype')=='vendor')
    {
      this.apiService.getOpenPickupRequests(localStorage.getItem('localityid')).subscribe((data)=>{
       // console.log(data);
        this.openpickups = data;
      });

      this.apiService.getOpenServiceRequets(localStorage.getItem('localityid')).subscribe((data)=>{
       // console.log(data);
        this.openservices = data;
      });

       this.apiService.getVendorPickups(this.userid).subscribe((data)=>{
       // console.log(data);
        this.pickups = data;
      });

      this.apiService.getVendorServices(this.userid).subscribe((data)=>{
       // console.log(data);
        this.services = data;
      });
    }
  	
  }

   isLoggedIn()
  {
    return localStorage.getItem('isLoggedIn');
  }

  getUserType()
  {
    return localStorage.getItem('usertype');
  }

  acceptRequest(type,id)
  {
    this.apiService.acceptRequest(type,id).subscribe((data:any)=>{

          alert(data.message);
          location.href = '/dashboard';
       
      });
  }

}
