<!-- loader.component.html -->
<!-- <div class="progress-loader overlay" [hidden]="!loading">
  <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div> -->
<div class="progress-loader" *ngIf="loading">

	<div class="loader"></div>
    
</div>
 